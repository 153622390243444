import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {Form, Upload, Button, Switch, Select, Row, Col} from "antd";
import {useEffect, useState} from "react";
import translation from "../../shared/utils/translation";
import FormInputItem from "../../shared/components/forms/FormInputItem";
import { BsInboxFill } from "react-icons/bs";
import Notifications from "../../shared/utils/Notifications";
import {toJS} from "mobx";
import {selectedLanguage} from "../../shared/stores/ConfigStore";
import CategoryStore from "../../shared/stores/CategoryStore";
import SubCategoryStore from "../../shared/stores/SubCategoryStore";
import {FormWrapper} from "../../shared/components/wrapper/FormWrapper";
import PreviewDisplay from "../../shared/components/PreviewDisplay";

const Wrapper = styled.div`
  margin: auto;
  padding: 10px;
`
const LabelText = styled.span`
  font-size: 15px;
`

const normFile = (e) => {

  if (Array.isArray(e)) {
    return e;
  }

  return e?.fileList;
};


const SubCategoryEdit = () => {
  const [base64String, setBase64String] = useState("")
  const [selectOptions, setSelectOptions] = useState([])
  const [swedishTranslationInputValue, setSwedishTranslationInputValue] = useState("")
  const [englishTranslationInputValue, setEnglishTranslationInputValue] = useState("")

  useEffect(() => {
    loadSelectOptions();
  }, [toJS(CategoryStore.category).length, selectOptions.length])

  const validateMessages = {
    required: "${name} is required",
  };

  const onFinish = async(values) => {
    let status = await SubCategoryStore.createSubCategory({values, base64String});

    if(status.success === true){
      Notifications("success", translation("common.success"), translation("notification.data.update.success"))
    }
    if(status.success === false){
      Notifications("error", translation("common.error"), translation("notification.data.update.error"))
    }
  };

  const onFinishFailed = () => {
    Notifications("error", translation("common.error"), translation("notification.fill.form"))
  };

  const beforeUpload = async(file) => {
    setBase64String(await toBase64(file))
  }
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const loadSelectOptions = () => {
    setSelectOptions([])
    toJS(CategoryStore.category).forEach((value) => {
      let option = {}
      option.label = `${value.id} - ${value.translation ? JSON.parse(value.translation)[selectedLanguage.get()] : translation(`category.${value.name}`)}`
      option.value = value.id;
      option.key = value.id;
      setSelectOptions(selectOptions => [...selectOptions, option])
    })
  }

  return (
    <Wrapper>
      <Row>
        <Col md={12} sm={24}>
          <Form name="dynamic_rule" validateMessages={validateMessages} onFinishFailed={onFinishFailed} onFinish={onFinish} initialValues={{remember: true}}>
            <FormWrapper>
              <LabelText>{translation("label.subcategory.name")}</LabelText>
              <FormInputItem
                name="name"
                required
              />
              <LabelText>{translation("common.se.translation")}</LabelText>
              <FormInputItem
                name="translationSe"
                required
                onChange={(event)=> setSwedishTranslationInputValue(event.target.value)}
              />
              <LabelText>{translation("common.en.translation")}</LabelText>
              <FormInputItem
                name="translationEn"
                required
                onChange={(event) => setEnglishTranslationInputValue(event.target.value)}
              />
              <LabelText>{translation("table.category.id")}</LabelText>
              <Form.Item
                name="categoryId"
                required
              >
                <Select
                  options={selectOptions}
                />
              </Form.Item>
              <LabelText>{translation("label.subcategory.image")}</LabelText>
              <Form.Item
                name="image"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                required
              >
                <Upload
                  name="logo"
                  listType="picture"
                  beforeUpload={beforeUpload}
                >
                  <Button icon={<BsInboxFill />}>Click to upload</Button>
                </Upload>
              </Form.Item>
              <LabelText>{translation("label.subcategory.visible")}</LabelText>
              <Form.Item
                name="visible"
                required
              >
                <Switch/>
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </FormWrapper>
          </Form>
        </Col>
        <Col md={12} sm={24}>
          <PreviewDisplay
            image={base64String}
            translationSe={swedishTranslationInputValue}
            translationEn={englishTranslationInputValue}
          />
        </Col>
      </Row>
    </Wrapper>
  )
}

export default observer(SubCategoryEdit)