import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import DataTable from "../../shared/components/table/DataTable";
import {toJS} from "mobx";
import translation from "../../shared/utils/translation";
import {useNavigate} from "react-router-dom";
import {setCookieEditingData} from "../../shared/stores/CookieStore";
import UserStore from "../../shared/stores/UserStore";

const Wrapper = styled.div`
  position: relative;
`

const CategoryList = () => {

  let navigate = useNavigate()

  let dataSource = toJS(UserStore.userList)

  let columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 10
    },
    {
      title: translation("common.username"),
      dataIndex: 'username',
      key: 'username',
      width: 100
    },
    {
      title: translation("common.access.level"),
      dataIndex: 'access_level',
      key: 'access_level',
      width: 10
    },
    {
      title: translation("common.access.auth.active"),
      dataIndex: 'auth_active',
      key: 'auth_active',
      width: 10
    },
    {
      title: translation("common.edit"),
      key: "action",
      render : (_, value) => (
        <a onClick={() => onEditClick(value)}>{translation("common.edit")}</a>
      ),
      width: 10
    }
  ]

  const onEditClick = (user) => {
    setCookieEditingData(JSON.stringify({id: user.id, username: user.username, access_level: user.access_level}));
    navigate(`/user/edit/${user.id}`);
  }

  return (
    <Wrapper>
      <DataTable
        dataSource={dataSource}
        columns={columns}
      />
    </Wrapper>
  )
}

export default observer(CategoryList)