export const gutter = 12
export const gridWidth = 1140
export const pageXLarge = 36
export const mobileHeaderHeight = 79
export const desktopHeaderHeight = 78
export const mobileInputRowHeight = 44
export const mobileSwapBarHeight = 150
export const rowHeight = 55
export const sectionMargin = "50px 0"

// fonts
export const mainFont = "'Montserrat', sans-serif;"
export const semi = "600"
export const bold = "bold"
export const extraBold = 800
export const smallerFontSize = "14px"

// colors

export const mainBackground = "#F4F4F4";
export const formHoverColor = "#C8C8C8";
export const buttonHoverColor = "";
export const TitleColor = "#000000"
export const subHeading = "rgb(50, 80, 25)"

// Placements

export const notificationPlacement = "bottomRight"

// Shadows

export const blackShadow = "0.05em 0 black, 0 0.05em black, -0.05em 0 black, 0 -0.05em black, -0.05em -0.05em black, -0.05em 0.05em black, 0.05em -0.05em black, 0.05em 0.05em black;"
export const whiteShadow = "0.05em 0 white, 0 0.05em white, -0.05em 0 white, 0 -0.05em white, -0.05em -0.05em white, -0.05em 0.05em white, 0.05em -0.05em white, 0.05em 0.05em white;"
