import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import translation from "../../shared/utils/translation";
import TitlePrimary from "../../shared/components/text/TitlePrimary";
import NewsCreate from "./NewsCreate";
import NewsList from "./NewsList";
import NewsEdit from "./NewsEdit";

const Wrapper = styled.div`
`

const ContentWrapper = styled.div`
  width: 80%;
  margin: auto;
`

const TitleWrapper = styled.div`
  margin: 50px;
`

const NewsWrapper = () => {
  let { event } = useParams();
  let { id } = useParams();
  return (
    <Wrapper>
      <TitleWrapper><TitlePrimary small={true}>{translation(`common.${event}`)}</TitlePrimary></TitleWrapper>
      <ContentWrapper>
        {event === "create" && <NewsCreate/>}
        {event === "list" && <NewsList/>}
        {event === "edit" && <NewsEdit id={id}/>}
      </ContentWrapper>
    </Wrapper>
  )
}

export default observer(NewsWrapper)