import styled from "styled-components";

export const FormWrapper = styled.div`
  width: 60%;
  @media (max-width: 1070px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`