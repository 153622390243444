import {makeAutoObservable} from "mobx";
import ApiGateway from "../api/ApiGateway";


class CategoryStore {

  category = []

  totalItems = null;
  isLoadingData = false;

  constructor() {
    makeAutoObservable(this)
  }

  async loadCategory() {
    const data = await ApiGateway.category.loadCategory();
    this.category = data.result ;
  }

  async getCategory(id) {
    let data = {id: id}
    const {result} = await ApiGateway.category.getCategory({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });

    return result[0];
  }

  async editCategory(data){
    let result = await ApiGateway.category.editCategory({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    return result;
  }

  async createCategory(data){
    let result = await ApiGateway.category.createCategory({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    return result;
  }

  getProductsImage() {

  }

}

export default new CategoryStore()