import React from "react"
import {
  selectedLanguage as configStoreSelectedLanguage,
  defaultLanguage as configStoreDefaultLanguage
} from "../stores/ConfigStore";
import {languages} from "../locales/languages";

export default function translation(string) {

  let selectedLanguage = configStoreSelectedLanguage.get() ? configStoreSelectedLanguage.get() : configStoreDefaultLanguage.get();

  let translatedText = languages[selectedLanguage][string];

  if(!translatedText){
    translatedText = string;
  }

  return translatedText

}
