import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`
const Main = styled.main`
  flex: 1;
  overflow-x: auto;
  margin: 48px 0 20px 0;  
`

const PageWrapper = ({children}) => {

    return (
      <Wrapper>
        <Main>{children}</Main>
      </Wrapper>
    )
}

export default observer(PageWrapper)