import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import DataTable from "../../shared/components/table/DataTable";
import {toJS} from "mobx";
import translation from "../../shared/utils/translation";
import {useNavigate} from "react-router-dom";
import NewsStore from "../../shared/stores/NewsStore";

const Wrapper = styled.div`
  position: relative;
`

const NewsList = () => {

  let navigate = useNavigate()

  let dataSource = toJS(NewsStore.news)

  let columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 10
    },
    {
      title: translation("common.title"),
      dataIndex: 'title',
      key: 'title',
      width: 100
    },
    {
      title: translation("common.image"),
      dataIndex: 'image',
      key: 'image',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
            textOverflow: 'ellipsis',
            overflow: "hidden"
          }
        }
      },
      width: 10
    },
    {
      title: translation("common.visible"),
      dataIndex: 'visible',
      key: 'visible',
      width: 10
    },
    {
      title: translation("common.tags"),
      dataIndex: 'tag_id',
      key: 'tag_id',
      width: 10
    },
    {
      title: translation("common.edit"),
      key: "action",
      render : (_, value) => (
        <a onClick={() => onEditClick(value)}>{translation("common.edit")}</a>
      ),
      width: 10
    }
  ]

  const onEditClick = (news) => {
    navigate(`/news/edit/${news.id}`);
  }

  return (
    <Wrapper>
      <DataTable
        dataSource={dataSource}
        columns={columns}
      />
    </Wrapper>
  )
}

export default observer(NewsList)