import React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {Layout as AntdLayout} from "antd";
import SideMenu from "./SideMenu";
import Header from "./Header";
import PageContent from "./PageContent";
import {BrowserRouter} from "react-router-dom";

const LayoutWrapper = styled(AntdLayout)`
  min-height: 100vh;
`
const ContentWrapper = styled(AntdLayout)`
`

const Layout = () => {
  return(
    <LayoutWrapper>
      <BrowserRouter>
        <SideMenu/>
        <ContentWrapper>
          <Header/>
          <PageContent/>
        </ContentWrapper>
      </BrowserRouter>
    </LayoutWrapper>
  )
}

export default observer(Layout)