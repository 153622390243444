import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import translation from "../../shared/utils/translation";
import TitlePrimary from "../../shared/components/text/TitlePrimary";
import UserList from "./UserList";
import UserCreate from "./UserCreate";
import UserEdit from "./UserEdit";

const Wrapper = styled.div`
`

const ContentWrapper = styled.div`
  width: 80%;
  margin: auto;
`

const TitleWrapper = styled.div`
  margin: 50px;
`

const UserWrapper = () => {
  let { event } = useParams();

  return (
    <Wrapper>
      <TitleWrapper><TitlePrimary small={true}>{translation(`common.${event}`)}</TitlePrimary></TitleWrapper>
      <ContentWrapper>
        {event === "list" && <UserList/>}
        {event === "edit" && <UserEdit/>}
        {event === "create" && <UserCreate/>}
      </ContentWrapper>
    </Wrapper>
  )
}

export default observer(UserWrapper)