import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {Form, Upload, Button, Switch, Row, Col} from "antd";
import {useState} from "react";
import translation from "../../shared/utils/translation";
import FormInputItem from "../../shared/components/forms/FormInputItem";
import { BsInboxFill } from "react-icons/bs";
import Notifications from "../../shared/utils/Notifications";
import CategoryStore from "../../shared/stores/CategoryStore";
import PreviewDisplay from "../../shared/components/PreviewDisplay";
import {FormWrapper} from "../../shared/components/wrapper/FormWrapper";

const Wrapper = styled.div`
  margin: auto;
  padding: 10px;
`

const LabelText = styled.span`
  font-size: 15px;
`

const normFile = (e) => {

  if (Array.isArray(e)) {
    return e;
  }

  return e?.fileList;
};


const CategoryCreate = () => {

  const [base64String, setBase64String] = useState("")
  const [swedishTranslation, setSwedishTranslation] = useState("")
  const [englishTranslation, setEnglishTranslation] = useState("")

  const validateMessages = {
    required: translation("common.field.required"),
  };

  const onFinish = async(values) => {

    let status = await CategoryStore.createCategory({values, base64String});
    if(status.success === true){
      Notifications("success", translation("common.success"), translation("notification.data.update.success"))
    }
    if(status.success === false){
      Notifications("error", translation("common.error"), translation("notification.data.update.error"))
    }

  };

  const onFinishFailed = () => {
    Notifications("error", translation("common.error"), translation("notification.fill.form"))
  };

  const beforeUpload = async(file) => {
    setBase64String(await toBase64(file))
  }
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });


  return (
    <Wrapper>
      <Row>
        <Col md={12} sm={24}>
          <Form name="dynamic_rule" validateMessages={validateMessages} onFinishFailed={onFinishFailed} onFinish={onFinish} initialValues={{remember: true}}>
            <FormWrapper>
              <LabelText>{translation("label.category.name")}</LabelText>
              <FormInputItem
                name="name"
                required
              />
              <LabelText>{translation("common.se.translation")}</LabelText>
              <FormInputItem
                name="translationSe"
                required
                onChange={(event) => setSwedishTranslation(event.target.value)}
              />
              <LabelText>{translation("common.en.translation")}</LabelText>
              <FormInputItem
                name="translationEn"
                required
                onChange={(event) => setEnglishTranslation(event.target.value)}
              />
              <LabelText>{translation("label.category.image")}</LabelText>
              <Form.Item
                name="image"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                required
              >
                <Upload
                  name="logo"
                  listType="picture"
                  beforeUpload={beforeUpload}
                >
                  <Button icon={<BsInboxFill />}>Click to upload</Button>
                </Upload>
              </Form.Item>
              <LabelText>{translation("label.category.visible")}</LabelText>
              <Form.Item name="visible" required>
                <Switch/>
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </FormWrapper>
          </Form>
        </Col>
        <Col md={12} sm={24}>
          <PreviewDisplay
            image={base64String}
            translationSe={swedishTranslation}
            translationEn={englishTranslation}
          />
        </Col>
      </Row>
    </Wrapper>
  )
}

export default observer(CategoryCreate)