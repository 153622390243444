import {notification} from "antd";
import {notificationPlacement} from "./vars";

const Notifications = (status, title, description) => {
  notification[status]({
    message: title,
    description: description,
    placement: notificationPlacement,
  });
}
export default Notifications;
