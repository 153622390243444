import {makeAutoObservable, toJS} from "mobx";
import ApiGateway from "../api/ApiGateway";


class SubCategoryStore {

  category = []
  subCategory = []

  totalItems = null;
  isLoadingData = false;

  constructor() {
    makeAutoObservable(this)
  }

  async loadSubCategory() {
    const data = await ApiGateway.subCategory.loadSubCategory();
    this.subCategory = data.result;
  }

  async getSubCategory(id) {
    let data = {id: id}
    const {result} = await ApiGateway.subCategory.getSubCategory({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    return result[0];
  }

  async editSubCategory(data){
    let result = await ApiGateway.subCategory.editSubCategory({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    return result;
  }

  async createSubCategory(data){
    let result = await ApiGateway.subCategory.createSubCategory({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    return result;
  }

  async deleteSubCategory(data){
    let result = await ApiGateway.subCategory.deleteSubCategory({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    if(result.success){
      this.subCategory = toJS(this.subCategory).filter(value => {
        return value.id != data.id;
      })
    }
    return result;
  }

  getProductsImage() {

  }

}

export default new SubCategoryStore()