import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {Form, Upload, Button, Switch, Select, Modal} from "antd";
import {useEffect, useState} from "react";
import translation from "../../shared/utils/translation";
import FormInputItem from "../../shared/components/forms/FormInputItem";
import {BsArrowLeftRight, BsInboxFill} from "react-icons/bs";
import Notifications from "../../shared/utils/Notifications";
import DraftTextEditor from "../../shared/components/input/DraftTextEditor";
import NewsStore from "../../shared/stores/NewsStore";
import {toJS} from "mobx";
import {selectedLanguage} from "../../shared/stores/ConfigStore";
import {convertFromRaw, EditorState} from "draft-js";

const Wrapper = styled.div`
  margin: auto;
  padding: 10px;
`

const LabelText = styled.span`
  font-size: 15px;
`
const FormWrapper = styled.div`
  width: 75%;
`

const normFile = (e) => {

  if (Array.isArray(e)) {
    return e;
  }

  return e?.fileList;
};


const NewsEdit = ({id}) => {
  const [base64String, setBase64String] = useState("")
  const [selectedTags, setSelectedTags] = useState([])
  const [selectOptions, setSelectOptions] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [swedishTagsTranslationInput, setSwedishTagsTranslationInput] = useState("")
  const [englishTagsTranslationInput, setEnglishTagsTranslationInput] = useState("")
  const [hasNews, setHasNews] = useState(false)
  const [news, setNews] = useState({})
  const [swedishEditorState, setSwedishEditorState] = useState({})
  const [englishEditorState, setEnglishEditorState] = useState({})
  const [visibleSwitchValue, setVisibleSwitchValue] = useState(false)
  const [swedishTranslationInput, setSwedishTranslationInput] = useState("")
  const [englishTranslationInput, setEnglishTranslationInput] = useState("")

  const [hasContent, setHasContent] = useState(false)


  useEffect(() => {
    NewsStore.loadNewsAndTags(id);
  }, [id])

  useEffect(() => {
    if(NewsStore.news.length === 1){
      loadSelectOptions()
      setNews(toJS(NewsStore.news[0]))
      setHasNews(true)
    }
  }, [NewsStore.news.length, selectOptions.length])

  useEffect(() => {
    if(news.id == id){
      let parsed = JSON.parse(news.content);
      let seParsed = JSON.parse(parsed.se);
      let seConvert = convertFromRaw(seParsed)

      let enParsed = JSON.parse(parsed.en)
      let enConvert = convertFromRaw(enParsed);
      setEnglishEditorState(EditorState.createWithContent(enConvert))
      setSwedishEditorState(EditorState.createWithContent(seConvert));

      setHasContent(true)
      setVisibleSwitchValue(news.visible)
    }
  }, [news])

  const loadSelectOptions = () => {
    setSelectOptions([])
    toJS(NewsStore.tags).forEach((value) => {
      let option = {}
      option.label = `${value.id} - ${JSON.parse(value.name)[selectedLanguage.get()]}`
      option.value = value.id;
      option.key = value.id;
      setSelectOptions(selectOptions => [...selectOptions, option])
    })
  }

  const validateMessages = {
    required: translation("common.field.required"),
  };

  const onFinish = async(values) => {
    let stringifyTags = JSON.stringify(selectedTags);
    let status = await NewsStore.editNews({values, content:{en:JSON.stringify(swedishTranslationInput), se:JSON.stringify(englishTranslationInput)}, news, stringifyTags, base64String});
    console.log(status)
    if(status.success === true){
      Notifications("success", translation("common.success"), translation("notification.data.update.success"))
    }
    if(status.success === false){
      Notifications("error", translation("common.error"), translation("notification.data.update.error"))
    }
  };

  const onFinishFailed = () => {
    Notifications("error", translation("common.error"), translation("notification.fill.form"))
  };

  const beforeUpload = async(file) => {
    setBase64String(await toBase64(file))
  }
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const tagCreateModal = () => {
    return(
      <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText="Create">
        <Form>
          <LabelText>{translation("common.se.translation")}</LabelText>
          <FormInputItem
            name={'translationSe'}
            required
            onChange={(e) => setSwedishTagsTranslationInput(e.target.value)}
          />
          <LabelText>{translation("common.en.translation")}</LabelText>
          <FormInputItem
            name={'translationEn'}
            required
            onChange={(e) => setEnglishTagsTranslationInput(e.target.value)}
          />
        </Form>
      </Modal>
    )
  }

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = async() => {
    setIsModalVisible(false);
    let status = await NewsStore.createTags({en: englishTagsTranslationInput, se: swedishTagsTranslationInput});
    if(status.success === true){
      Notifications("success", translation("common.success"), translation("notidfication.data.created.success"))
    }
    if(status.success === false){
      Notifications("error", translation("common.error"), translation("notidfication.data.created.error"))
    }
  };

  return (
    <Wrapper>
      {
      hasNews &&
      <Form name="dynamic_rule" validateMessages={validateMessages} onFinishFailed={onFinishFailed} onFinish={onFinish} initialValues={{remember: true}}>
        {tagCreateModal()}
        <FormWrapper>
          <LabelText>{translation("label.title.se")}</LabelText>
          <FormInputItem
            name="titleSE"
            required
            style={{width: "20%"}}
            initialValue={JSON.parse(news.title).se}
          />
          <LabelText>{translation("label.title.en")}</LabelText>
          <FormInputItem
            name="titleEN"
            required
            style={{width: "20%"}}
            initialValue={JSON.parse(news.title).en}
          />
          <LabelText>{translation("label.image")}</LabelText>
          <Form.Item
            name="image"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            required
          >
            <Upload
              name="logo"
              listType="picture"
              beforeUpload={beforeUpload}
            >
              <Button icon={<BsInboxFill />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          {hasContent &&
            <>
              <LabelText>{translation("common.se.translation")}</LabelText>
              <DraftTextEditor
                onChange={(val) => setSwedishTranslationInput(val)}
                textObject={swedishEditorState}
                isEditor={true}
              />
              <LabelText>{translation("common.en.translation")}</LabelText>
              <DraftTextEditor
                onChange={(val) => setEnglishTranslationInput(val)}
                textObject={englishEditorState}
                isEditor={true}
              />
            </>
          }
          <LabelText>Tag</LabelText>
          <Form.Item>
            <Select
              mode="multiple"
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              options={selectOptions}
              onChange={(value)=> setSelectedTags(value)}
              defaultValue={JSON.parse(news.tag_id)}
            />
          </Form.Item>
          <Button type="dashed" onClick={showModal} block icon={<BsArrowLeftRight />}>
            {translation("button.display.create.tag")}
          </Button>
          <LabelText>{translation("common.visible")}</LabelText>
          <Form.Item
            name="visible"
            required
          >
            <Switch checked={visibleSwitchValue} onChange={() => setVisibleSwitchValue(!visibleSwitchValue)}/>
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </FormWrapper>
      </Form>
      }
    </Wrapper>
  )
}

export default observer(NewsEdit)