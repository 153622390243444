import React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {Layout } from "antd";

const AntdHeader = styled(Layout.Header)`
  display: flex;
  align-items: center;
`

const Header = () => {

  return(
    <AntdHeader>
    </AntdHeader>
  )
}

export default observer(Header)