import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {Form, Upload, Button, Switch, Row, Col} from "antd";
import TitleSecondary from "../../shared/components/text/TitleSecondary";
import {useEffect, useState} from "react";
import translation from "../../shared/utils/translation";
import FormInputItem from "../../shared/components/forms/FormInputItem";
import { BsInboxFill } from "react-icons/bs";
import Notifications from "../../shared/utils/Notifications";
import CategoryStore from "../../shared/stores/CategoryStore";
import {useParams} from "react-router-dom";
import PreviewDisplay from "../../shared/components/PreviewDisplay";
import {FormWrapper} from "../../shared/components/wrapper/FormWrapper";
import TimelineDisplay from "../../shared/components/TimelineDisplay";
import LogStore from "../../shared/stores/LogStore";

const Wrapper = styled.div`
  margin: auto;
  padding: 10px;
`

const TitleWrapper = styled.div`
  margin-bottom: 10px;
`

const LabelText = styled.span`
  font-size: 15px;
`

const normFile = (e) => {

  if (Array.isArray(e)) {
    return e;
  }

  return e?.fileList;
};


const CategoryEdit = () => {

  const [category, setCategory] = useState({})
  const [history, setHistory] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [nameInputValue, setNameInputValue] = useState()
  const [swedishTranslationInputValue, setSwedishTranslationInputValue] = useState()
  const [englishTranslationInputValue, setEnglishTranslationInputValue] = useState()
  const [visibleSwitchValue, setVisibleSwitchValue] = useState()
  const [base64String, setBase64String] = useState("")

  let { id } = useParams();
  useEffect(() => {
    loadCategory(id);
  },[id])

  const loadCategory = async(id) => {
    let categoryResult = await CategoryStore.getCategory(id);
    let parsedTranslation = JSON.parse(categoryResult.translation)
    setCategory(categoryResult)
    setSwedishTranslationInputValue(parsedTranslation.se !== undefined ? parsedTranslation.se : "")
    setEnglishTranslationInputValue(parsedTranslation.en !== undefined ? parsedTranslation.en : "")
    setNameInputValue(categoryResult.name)
    setVisibleSwitchValue(categoryResult.visible)
    await setBase64String(categoryResult.image)

    let categoryLogHistory = await LogStore.getLogs({object_id: id, log_category: 1});
    setHistory(categoryLogHistory)

    setIsLoading(false)
  }

  const validateMessages = {
    required: translation("common.field.required"),
  };

  const onFinish = async(values) => {
    let status = await CategoryStore.editCategory({values, id:category.id, base64String});
    if(status.success === true){
      Notifications("success", translation("common.success"), translation("notification.data.update.success"))
    }
    if(status.success === false){
      Notifications("error", translation("common.error"), translation("notification.data.update.error"))
    }
  };

  const onFinishFailed = () => {
    Notifications("error", translation("common.error"), translation("notification.fill.form"))
  };

  const beforeUpload = async(file) => {
    setBase64String(await toBase64(file))
  }
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  return (
    <Wrapper>
      {!isLoading &&
        <>
        <TitleWrapper>
          <TitleSecondary>{category.id} - {translation(`category.${category.name}`)}</TitleSecondary>
        </TitleWrapper>
        <Row>
          <Col lg={8} md={24}>
            <Form name="dynamic_rule" validateMessages={validateMessages} onFinishFailed={onFinishFailed} onFinish={onFinish} initialValues={{remember: true}}>
              <FormWrapper>
                <LabelText>{translation("label.category.name")}</LabelText>
                <FormInputItem
                  name="name"
                  required
                  onChange={(event) => {setNameInputValue(event.target.value)}}
                  initialValue={nameInputValue}
                />
                <LabelText>{translation("common.se.translation")}</LabelText>
                <FormInputItem
                  name="translationSe"
                  required
                  onChange={(event) => {setSwedishTranslationInputValue(event.target.value)}}
                  initialValue={swedishTranslationInputValue}
                />
                <LabelText>{translation("common.en.translation")}</LabelText>
                <FormInputItem
                  name="translationEn"
                  required
                  onChange={(event) => {setEnglishTranslationInputValue(event.target.value)}}
                  initialValue={englishTranslationInputValue}
                />
                <LabelText>{translation("label.category.image")}</LabelText>
                <Form.Item
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    name="logo"
                    listType="picture"
                    beforeUpload={beforeUpload}
                  >
                    <Button icon={<BsInboxFill />}>Click to upload</Button>
                  </Upload>
                </Form.Item>
                <LabelText>{translation("label.category.visible")}</LabelText>
                <Form.Item name="visible" >
                  <Switch checked={visibleSwitchValue} onChange={() => setVisibleSwitchValue(!visibleSwitchValue)}/>
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </FormWrapper>
            </Form>
          </Col>
          <Col lg={8} md={24}>
            <PreviewDisplay
              image={base64String}
              translationSe={swedishTranslationInputValue}
              translationEn={englishTranslationInputValue}
            />
          </Col>
          <Col lg={8} md={24}>
            <TimelineDisplay object={history}/>
          </Col>
        </Row>
        </>
      }
    </Wrapper>
  )
}

export default observer(CategoryEdit)