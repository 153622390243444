import React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import LanguageSelect from "./LanguageSelect";
import UserStore from "../stores/UserStore";
import { BiLogOut } from "react-icons/bi";

const FooterWrapper = styled.section`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  border: 1px solid black;
  width: 100%;
  background-color: gray;
  justify-content: space-between;

`

const UsernameText = styled.span`
  max-width: 15ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Wrapper = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
`

const LogoutButton = styled(BiLogOut)`
  font-size: 24px;
`

const Footer = ({}) => {
  return(
   <FooterWrapper>
     <LanguageSelect position={"header"}/>
     <Wrapper>
       <UsernameText>{UserStore.username}</UsernameText>
     </Wrapper>
     <Wrapper>
       <LogoutButton onClick={() => UserStore.logOut()}/>
     </Wrapper>

   </FooterWrapper>
  )
}

export default observer(Footer)