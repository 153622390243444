import * as React from "react"
import {observer} from "mobx-react-lite";
import {Table} from "antd";

const DataTable = ({dataSource, columns, onRow, onSelect}) => {


  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      onRow={onRow}
      onSelect={onSelect}
    />
  )
}

export default observer(DataTable)