import AdminApi from "./AdminApi";

export const BASE_URL = "admin-api/api"

export default {
  category: {
    loadCategory: async () => {
      const data = await AdminApi(`${BASE_URL}/category/load-category`)
      return data
    },
    getCategory: async (config) => {
      const data = await AdminApi(`${BASE_URL}/category/get-category`, config)
      return data
    },
    editCategory: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/category/edit-category`, config)
      return data
    },
    createCategory: async (config)=> {
      const  data  = await AdminApi(`${BASE_URL}/category/create-category`, config)
      return data
    },
  },
  subCategory: {
    loadSubCategory: async () => {
      const  data  = await AdminApi(`${BASE_URL}/subcategory/load-subcategory`)
      return data
    },
    getSubCategory: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/subcategory/get-subcategory`, config)
      return data
    },
    editSubCategory: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/subcategory/edit-subcategory`, config)
      return data
    },
    createSubCategory: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/subcategory/create-subcategory`, config)
      return data
    },
    deleteSubCategory: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/subcategory/delete-subcategory`, config)
      return data
    }
  },
  user: {
    createUser: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/user/create`, config)
      return data
    },
    loginUser: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/user/login`, config)
      return data
    },
    loadUser: async () => {
      const  data  = await AdminApi(`${BASE_URL}/user/get-userlist`)
      return data
    },
    editUser: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/user/edit-user`, config)
      return data
    }
  },
  auth: {
    authToken: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/auth/authenticate-token`, config)
      return data
    },
  },
  news: {
    loadNews: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/news/get-news`, config)
      return data
    },
    loadTags: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/news/get-tags`, config)
      return data
    },
    createTags: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/news/create-tags`, config)
      return data
    },
    createNews: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/news/create-news`, config)
      return data
    },
    editNews: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/news/edit-news`, config)
      return data
    },
  },
  logs:{
    loadLogs: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/logs/load-logs`, config)
      return data
    },
    getLogs: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/logs/get-logs`, config)
      return data
    },
    addLogs: async (config) => {
      const  data  = await AdminApi(`${BASE_URL}/logs/add-logs`, config)
      return data
    },
  }

}