import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";

const Wrapper = styled.div`
`

const ContentWrapper = styled.div`
  width: 80%;
  margin: auto;
`
const HomeWrapper = () => {

  return (
    <Wrapper>
      <ContentWrapper>
        <h1>Home</h1>
      </ContentWrapper>
    </Wrapper>
  )
}

export default observer(HomeWrapper)