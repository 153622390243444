import React from "react"
import styled from "styled-components"

const Text = styled.span`
  font-size: ${p => p.small ? "32px" : "48px"};
  font-weight: bold;
  line-height: 1.25;
  ${p => p.styling};
`

const TitlePrimary = ({ children, small = false, slim = false, styling, twoLine = false, text }) => {
  return(
    <>
      {
        twoLine ?
          <>
            <Text
              small={small}
              slim={slim}
              styling={styling}
            >
              {text["line1"]}
            </Text>
            <br/>
            <Text
              small={small}
              slim={slim}
              styling={styling}
            >
              {text["line2"]}
            </Text>
          </>
          :
          <Text
            small={small}
            slim={slim}
            styling={styling}
          >
            {children}
          </Text>
      }
    </>

  )
}

export default TitlePrimary