import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {Form, Button, Slider} from "antd";
import {useState} from "react";
import translation from "../../shared/utils/translation";
import FormInputItem from "../../shared/components/forms/FormInputItem";
import Notifications from "../../shared/utils/Notifications";
import UserStore from "../../shared/stores/UserStore";
import {accessLevelMeaning} from "../../shared/utils/config/accessLevelDescription";

const Wrapper = styled.div`
  margin: auto;
  padding: 10px;
`

const LabelText = styled.span`
  font-size: 15px;
`
const FormWrapper = styled.div`
  width: 20%;
`

const UserCreate = () => {

  const [accessLevelSliderValue, setAccessLevelSliderValue] = useState(0);

  const validateMessages = {
    required: translation("common.field.required"),
  };

  const onFinish = async(values) => {
    let status = await UserStore.createUser({values});
    if(status.success === true){
      Notifications("success", translation("common.success"), translation("notification.data.create.account.success"))
    }
    if(status.success === false){
      Notifications("error", translation("common.error"), translation("notification.data.create.account.error"))
    }

  };

  const onFinishFailed = () => {
    Notifications("error", translation("common.error"), translation("notification.fill.form"))
  };

  return (
    <Wrapper>
      <Form name="dynamic_rule" validateMessages={validateMessages} onFinishFailed={onFinishFailed} onFinish={onFinish} initialValues={{remember: true}}>
        <FormWrapper>
          <LabelText>{translation("common.username")}</LabelText>
          <FormInputItem
            name="username"
            required
          />
          <LabelText>{translation("common.password")}</LabelText>
          <FormInputItem
            name="password"
            required
            isPassword={true}
            inputRules={{ min: 5, message: 'Min 5 characters.' }}
          />
          <LabelText>{translation("common.access.level")}</LabelText>
          <Form.Item
            name="access_level"
            required>
            <Slider min={0} max={5} onChange={setAccessLevelSliderValue} value={accessLevelSliderValue} />
          </Form.Item>
          <p style={{marginBottom: "10px"}}>{translation("common.access")}: {accessLevelMeaning[accessLevelSliderValue]}</p>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </FormWrapper>
      </Form>
    </Wrapper>
  )
}

export default observer(UserCreate)