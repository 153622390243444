import React from "react"
import styled from "styled-components"
import {media} from "../utils/media";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";

const HomeLink = styled.div`
  cursor: pointer;
  img {
    display: block;
    max-height: ${p => p.position === "header" ? "75px" : "12.5em"};
    ${media.tablet`
    max-height: ${p => p.position === "header" ? "60px" : "6.2em"};
    `};
    ${media.phone`
    max-height: ${p => p.position === "header" ? "45px" : "6.2em"};
    `};
  }
`

const Logo = ({position = "header"}) => {
  const imageUrl = `/static/logo/logo-white.png`

  return (
    <HomeLink position={position}>
      <img alt="logo" src={imageUrl} />
    </HomeLink>
  )
}

export default observer(Logo)