import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import DataTable from "../../shared/components/table/DataTable";
import {toJS} from "mobx";
import translation from "../../shared/utils/translation";
import {useNavigate} from "react-router-dom";
import {setCookieEditingData} from "../../shared/stores/CookieStore";
import {Image, Popconfirm, Popover} from "antd";
import Notifications from "../../shared/utils/Notifications";
import SubCategoryStore from "../../shared/stores/SubCategoryStore";

const Wrapper = styled.div`
  position: relative;
`

const SubCategoryList = () => {

  let navigate = useNavigate()

  let dataSource = toJS(SubCategoryStore.subCategory)

  let columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 10
    },
    {
      title: translation("table.category.id"),
      dataIndex: 'category_id',
      key: 'category_id',
      width: 10
    },
    {
      title: translation("common.name"),
      dataIndex: 'name',
      key: 'name',
      width: 100
    },
    {
      title: translation("common.translation"),
      dataIndex: 'translation',
      key: 'translation',
      width: 100
    },
    {
      title: translation("common.image"),
      dataIndex: 'image',
      key: 'image',
      render: (image) => (
        <Popover placement="topLeft" content={<Image style={{maxHeight: "250px", maxWidth: "250px"}} src={image}/>}>
          {image}
        </Popover>
      ),
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
            textOverflow: 'ellipsis',
            overflow: "hidden"
          }
        }
      },
      width: 10
    },
    {
      title: translation("common.visible"),
      dataIndex: 'visible',
      key: 'visible',
      width: 10
    },
    {
      title: translation("common.edit"),
      key: "action",
      render : (_, value) => (
        <>
          <a onClick={() => onEditClick(value)}>{translation("common.edit")}</a>
          <br/>
          <Popconfirm placement="top" title={`${value.id} - ${translation("confirm.delete.subcategory")}`} onConfirm={() => onDeleteClick(value)} okText="Yes" cancelText="No">
            <a>{translation("common.delete")}</a>
          </Popconfirm>
        </>
      ),
      width: 10
    }
  ]

  const onEditClick = (subcategory) => {
    setCookieEditingData(JSON.stringify({id: subcategory.id, categoryId: subcategory.category_id, name: subcategory.name, translation: subcategory.translation ? JSON.parse(subcategory.translation) : "", visible: subcategory.visible}));
    navigate(`/subcategory/edit/${subcategory.id}`);
  }

  const onDeleteClick = async(subcategory) => {
    let status = await SubCategoryStore.deleteSubCategory({id: subcategory.id});
    if(status.success === true){
      Notifications("success", translation("common.success"), translation("notification.data.delete.success"))
    }
    if(status.success === false){
      Notifications("error", translation("common.error"), translation("notification.data.delete.error"))
    }
  }

  return (
    <Wrapper>
      <DataTable
        dataSource={dataSource}
        columns={columns}
      />
    </Wrapper>
  )
}

export default observer(SubCategoryList)