import {makeAutoObservable} from "mobx";
import ApiGateway from "../api/ApiGateway";
import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";

class UserStore {

  userList = []
  accessLevel = 0
  isLoggedIn = false
  userId = null
  username = ""

  constructor() {
    makeAutoObservable(this)
  }

  async loadUser(){
    const data = await ApiGateway.user.loadUser();
    this.userList = data.result;
  }

  async loadUserData(){
    let sessionToken = {token: Cookie.get("SID")};
    if(!sessionToken){
      this.isLoggedIn = false;
      window.location.pathname = '/login'
      return
    }
    let status = await this.authenticateUser(sessionToken)

    if(status.auth === false){
      Cookie.remove('logged_in')
      Cookie.remove('SID')
      this.isLoggedIn = false;
      this.accessLevel = 0;
      this.userId = null;
      this.username = ""
      if(window.location.pathname.split("/",2)[1] != "login"){
        window.location.pathname = '/login'
      }
    }
    if(status.auth === true){
      let decoded = jwt_decode(sessionToken.token);
      this.accessLevel = decoded.access_level;
      this.isLoggedIn = true;
      this.userId = decoded.user_id
      this.username = decoded.username
    }
  }

  async authenticateUser(sessionToken){
    let result = await ApiGateway.auth.authToken({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(sessionToken)
    });
    return result;
  }

  async createUser(data){
    let result = await ApiGateway.user.createUser({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    return result;
  }

  async loginUser(data){
    let result = await ApiGateway.user.loginUser({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    return result;
  }

  async editUser(data){
    let result = await ApiGateway.user.editUser({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    return result;
  }

  async logOut(){
    Cookie.remove('logged_in')
    Cookie.remove('SID')
    this.isLoggedIn = false;
    this.accessLevel = 0;
    this.userId = null;
    this.username = ""
    window.location.pathname = '/login'
  }

}

export default new UserStore()