import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {Switch} from "antd";
import {useState} from "react";
import Flag from "react-flagkit";

const PreviewWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`
const PreviewImage = styled.img`
  width: 15em;
  height: 15em;
  border-radius: 50%;
  object-fit: cover;
  
  @media (max-width: 800px) {
    width: 12em;
    height: 12em;
  }
`
const PreviewText = styled.span`
  font-size: 32px;
  font-weight: 700;
`
const PreviewLanguageSwitch = styled(Switch)`
  margin-bottom: 25px;
`

const PreviewDisplay = ({image, translationSe, translationEn}) => {
  const [previewLanguage, setPreviewLanguage] = useState(true)
  return (
    <PreviewWrapper>
      <PreviewLanguageSwitch
        defaultChecked
        checkedChildren={<Flag country={"SE"}  />}
        unCheckedChildren={<Flag country={"GB"}  />}
        onChange={() => setPreviewLanguage(!previewLanguage)}/>
      <PreviewImage
        src={image}
      />
      <PreviewText>{previewLanguage ? translationSe : translationEn}</PreviewText>
    </PreviewWrapper>
  )
}

export default observer(PreviewDisplay)