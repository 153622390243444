import * as React from "react"
import {Form, Input} from "antd"

class FormInputItem extends React.Component {
  static defaultProps = {
    required: false,
    disabled: false,
    isTextArea: false,
    rules: []
  }

  render() {
    const {
      style,
      name,
      required,
      initialValue,
      disabled,
      isTextArea,
      onChange,
      type = "text",
      innerRef,
      isPassword,
      inputRules,
      label,
      addonAfter,
      addonBefore
    } = this.props

    return (
      <Form.Item
        name={name}
        rules={[
        {
          required: required,
          ...inputRules
        }]}
        initialValue={initialValue}
        label={label}
        addonAfter={addonAfter}
        addonBefore={addonBefore}
      >
      {
      (
        isTextArea ? (
          <Input.TextArea style={style} showCount maxLength={200} allowClear value={initialValue} ref={innerRef} disabled={disabled}/>
        )
        :
          isPassword ? (
            <Input.Password type={type} style={style} defaultValue={initialValue} initialValue={initialValue} ref={innerRef} disabled={disabled}/>
          )
          :
         (
            <Input type={type} onChange={onChange} style={style} defaultValue={initialValue} initialValue={initialValue} ref={innerRef} disabled={disabled}/>
          )
      )}
      </Form.Item>
    )
  }
}

export default FormInputItem