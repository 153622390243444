import React from "react"
import { action } from "mobx"
import Cookie from "js-cookie";

export const setCookieLanguage = action((lang) => {
  if(!lang)throw "No entered language"
  Cookie.set("selectedLanguage", lang)
})

export const setCookieEditingData = action((category) => {
  if(!category)throw "No entered category"
  Cookie.set("editData", category);
})

export const setUserLoggedIn = action((token) => {
  if(!token)throw "No entered token"
  Cookie.set("logged_in", true, {expires: 1});
  Cookie.set("SID", token, {expires: 1});
})