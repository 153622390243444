import React from "react"
import {observer} from "mobx-react-lite";
import {Layout} from "antd";
import Menu from "./Menu";
import Logo from "../Logo";
import styled from "styled-components"
import Footer from "../Footer";

const LogoWrapper = styled.div`
  margin-bottom: 10px;
`


const SideMenu = () => {
  return(
    <Layout.Sider>
      <LogoWrapper>
        <Logo/>
      </LogoWrapper>

      <Menu/>
      <Footer/>
    </Layout.Sider>
  )
}

export default observer(SideMenu)