import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite";
import {Menu as AntdMenu} from "antd";
import MenuItems from "../../utils/config/MenuItems";
import {MenuItemsConfig} from "../../utils/config/MenuItemsConfig";

const Menu = () => {
  const [openKeys, setOpenKeys] = useState([]);
  const [keys] = useState([]);

  let items = MenuItemsConfig;

  useEffect(() => {
    loadId();
  },[])

  const loadId = () => {
    for(let i = 0; i < items.length; i++){
      keys.push(items[i].key)
    }
  }

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (keys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return(
      <AntdMenu
        mode="inline"
        theme="dark"
        items={MenuItems()}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      />
  )
}

export default observer(Menu)