import {MenuItemsConfig} from "./MenuItemsConfig";
import UserStore from "../../stores/UserStore";
import styled from "styled-components"
import translation from "../translation";
import {useNavigate} from "react-router-dom";

const ItemTitleText = styled.span`
  display: flex;
  align-items: center;
  font-size: 20px;
`

export default function MenuItems(){
  const items = MenuItemsConfig;
  let navigate = useNavigate();

  const MenuItems = []

  items.map((value)=> {
    if(value.requiredAccessLevel <= UserStore.accessLevel){ //Switch
      let menuItem = value.name;
      value.label = <ItemTitleText>{value.iconNode} {translation(`menu.title.${value.name}`)}</ItemTitleText>
      value.children.map((value)=> {
        value.label = <ItemTitleText onClick={() => navigate(`/${menuItem}/${value.name}`)}> {translation(`submenu.${value.name}`)}</ItemTitleText>
      })
      MenuItems.push(value);
    }
  })

  return MenuItems;

}