import { createGlobalStyle } from "styled-components"
import {formHoverColor} from "../utils/vars"
export default createGlobalStyle`
  html {

  }
  html, body, #__next {
    height: 100%;
    width: 100%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  * {
    padding: 0;
    margin: 0;
    outline: none;
  }
  body {
    font-family: 'Montserrat', sans-serif;
    //add color
    font-weight: 500;
    font-size: 16px;
    background: #F4F4F4;
  }
  p{
    margin: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  input {
    border: none;
    outline: none;
    font-size: 16px;
  }
  h1 {
    font-weight: 800;
    font-size: 50px;
  }
  .ant-row {
    margin: 0;
  }
  .ant-input-textarea > .ant-input-affix-wrapper > textarea{
    font-size: 14px;
    border: 1px solid gray;
    background-color : gainsboro !important;
    padding: 10px 20px;
    height: 200px;
    resize: none;
  }
  .ant-input:hover, .ant-input-textarea > .ant-input-affix-wrapper > textarea:hover{
    //background-color: ${formHoverColor} !important;
  }
  
  //Draft Editor
  .RichEditor-root {
    background: #fff;
    border: 1px solid #ddd;
    font-family: 'Georgia', serif;
    font-size: 14px;
    padding: 15px;
  }

  .RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
  }

  .RichEditor-editor .public-DraftEditorPlaceholder-root,
  .RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
  }

  .RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
  }

  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }

  .RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }

  .RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
  }

  .RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
  }

  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }

  .RichEditor-activeButton {
    color: #5890ff;
  }
  .align-right div {
    text-align: right;
  }
  .align-center div {
    text-align: center;
  }
  .align-left div {
    text-align: left;
  }
  .title div {
    text-align: center;
    font-size:  26pt;
  }
  
`