import './App.css';
import React from "react"
import language from "./shared/utils/language";
import Layout from "./shared/components/layout/Layout";
import accessCheck from "./shared/utils/config/access/accessCheck"
import UserStore from "./shared/stores/UserStore";

class App extends React.Component{

  async componentDidMount(){

    await UserStore.loadUserData();
    let access = await accessCheck();
    if(access === false){
      window.location.pathname = '/'
    }

  }
  render(){
    language()

    return <Layout/>
  }
}

export default App;