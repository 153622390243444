import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {Route, Routes} from "react-router-dom";
import CategoryWrapper from "../../../features/category/CategoryWrapper";
import SubCategoryWrapper from "../../../features/subcategory/SubCategoryWrapper";
import UserWrapper from "../../../features/users/UserWrapper";
import LoginWrapper from "../../../features/login/LoginWrapper";
import HomeWrapper from "../../../features/home/HomeWrapper";
import NewsWrapper from "../../../features/news/NewsWrapper";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const PageContent = () => {

  return (
    <Wrapper>
      <Routes>
        <Route path="/" element={<HomeWrapper/>}/>
        <Route path="/category/:event">
          <Route path=":id" element={<CategoryWrapper />} />
          <Route path="" element={<CategoryWrapper />} />
        </Route>
        <Route path="/subcategory/:event">
          <Route path=":id" element={<SubCategoryWrapper />} />
          <Route path="" element={<SubCategoryWrapper />} />
        </Route>
        <Route path="/user/:event">
          <Route path=":id" element={<UserWrapper />} />
          <Route path="" element={<UserWrapper />} />
        </Route>
        <Route path="/news/:event">
          <Route path=":id" element={<NewsWrapper />} />
          <Route path="" element={<NewsWrapper />} />
        </Route>
        <Route path="/login" element={<LoginWrapper />}/>
      </Routes>
    </Wrapper>

  )
}

export default observer(PageContent)