import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import DataTable from "../../shared/components/table/DataTable";
import {toJS} from "mobx";
import translation from "../../shared/utils/translation";
import {useNavigate} from "react-router-dom";
import CategoryStore from "../../shared/stores/CategoryStore";
import {Image, Popover} from "antd";

const Wrapper = styled.div`
  position: relative;
`

const CategoryList = () => {

  let navigate = useNavigate()

  let dataSource = toJS(CategoryStore.category)

  let columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 10
    },
    {
      title: translation("common.name"),
      dataIndex: 'name',
      key: 'name',
      width: 100
    },
    {
      title: translation("common.translation"),
      dataIndex: 'translation',
      key: 'translation',
      width: 100
    },
    {
      title: translation("common.image"),
      dataIndex: 'image',
      key: 'image',
      render: (image) => (
        <Popover placement="topLeft" content={<Image style={{maxHeight: "250px", maxWidth: "250px"}} src={image}/>}>
          {image}
        </Popover>
      ),
        onCell: () => {
          return {
            style: {
              whiteSpace: 'nowrap',
              maxWidth: 150,
              textOverflow: 'ellipsis',
              overflow: "hidden"
            }
          }
        },
      width: 10
    },
    {
      title: translation("common.visible"),
      dataIndex: 'visible',
      key: 'visible',
      width: 10
    },
    {
      title: translation("common.edit"),
      key: "action",
      render : (_, value) => (
        <a onClick={() => onEditClick(value)}>{translation("common.edit")}</a>
      ),
      width: 10
    }
  ]

  const onEditClick = (category) => {
    navigate(`/category/edit/${category.id}`);
  }

  return (
    <Wrapper>
      <DataTable
        dataSource={dataSource}
        columns={columns}
      />
    </Wrapper>
  )
}

export default observer(CategoryList)