import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {Form, Button, Slider} from "antd";
import TitleSecondary from "../../shared/components/text/TitleSecondary";
import {useState} from "react";
import Cookie from "js-cookie"
import translation from "../../shared/utils/translation";
import Notifications from "../../shared/utils/Notifications";
import {accessLevelMeaning} from "../../shared/utils/config/accessLevelDescription";
import UserStore from "../../shared/stores/UserStore";
import FormInputItem from "../../shared/components/forms/FormInputItem";

const Wrapper = styled.div`
  margin: auto;
  padding: 10px;
`

const TitleWrapper = styled.div`
  margin-bottom: 10px;
`

const LabelText = styled.span`
  font-size: 15px;
`
const FormWrapper = styled.div`
  width: 15%;
`

const UserEdit = () => {

  const [user] = useState(JSON.parse(Cookie.get("editData")))
  const [accessLevelSliderValue, setAccessLevelSliderValue] = useState(user.access_level)

  const validateMessages = {
    required: "${name} is required",
  };

  const onFinish = async(values) => {

    let status = await UserStore.editUser({values, user});

    if(status.success === true){
      Notifications("success", translation("common.success"), translation("notification.data.update.success"))
    }
    if(status.success === false){
      Notifications("error", translation("common.error"), translation("notification.data.update.error"))
    }
  };

  const onFinishFailed = () => {
    Notifications("error", translation("common.error"), translation("notification.fill.form"))
  };


  return (
    <Wrapper>
      <TitleWrapper>
        <TitleSecondary>{user.id} - {user.username}</TitleSecondary>
      </TitleWrapper>
      <Form name="dynamic_rule" validateMessages={validateMessages} onFinishFailed={onFinishFailed} onFinish={onFinish} initialValues={{remember: true}}>
        <FormWrapper>
          <LabelText>{translation("common.password")}</LabelText>
          <FormInputItem
            inputRules={{ min: 5, message: 'Min 5 characters.' }}
            name="password"
            isPassword={true}
          />
          <LabelText>{translation("common.access.level")}</LabelText>
          <Form.Item
            name="access_level"
            required
            initialValue={accessLevelSliderValue}>
            <Slider min={0} max={5} defaultValue={accessLevelSliderValue} onChange={setAccessLevelSliderValue} value={accessLevelSliderValue} />
          </Form.Item>
          <p style={{marginBottom: "10px"}}>{translation("common.access")}: {accessLevelMeaning[accessLevelSliderValue]}</p>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </FormWrapper>
      </Form>
    </Wrapper>
  )
}

export default observer(UserEdit)