import {makeAutoObservable} from "mobx";
import ApiGateway from "../api/ApiGateway";
import UserStore from "./UserStore";

class LogStore {


  constructor() {
    makeAutoObservable(this)
  }

  async loadLogs() {
    const data = await ApiGateway.logs.loadLogs();
    this.category = data.result ;
  }

  async getLogs(val) {

    let data = {
      object_id: val.object_id,
      log_category: val.log_category
    }
    let {result} = await ApiGateway.logs.getLogs({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    return result;
  }

  async addLogs(val){
    let data = {
      object_id: val.id,
      log_type: val.log_type,
      log_category: val.category,
      log_note: val.log_note,
      user_id: UserStore.userId
    }

    let result = await ApiGateway.logs.addLogs({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    return result;

  }

}

export default new LogStore()