import Cookie from "js-cookie";
import LogStore from "../stores/LogStore";

export const API_URL = "https://www.studydeluxe.com/"

export default async function AdminApi(path, config){
  let sendConfig = config;
  if(sendConfig === undefined){
    sendConfig = {
      headers:{
        token: Cookie.get("SID")
      }
    }
  }else{
    sendConfig.headers.token = Cookie.get("SID");
  }
  const response = await fetch(`${API_URL}${path}`, sendConfig);
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const result = await response.json();

  if(config){
    let log_type = path.includes("edit") ? "edit" : path.includes("create") ? "create" : path.includes("delete") ? "delete" : "error";

    if(log_type != "error"){
      let id = await JSON.parse(config.body).id
      let category = path.split("edit", 2)[1].slice(1);

      LogStore.addLogs({category: category, log_type: log_type, id:id})
    }
  }

  return result;
}