import { RiEditCircleLine } from "react-icons/ri";
import { AiOutlinePlusCircle, AiOutlineCloseCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import styled from "styled-components";

const AddIcon = styled(AiOutlinePlusCircle)`
  font-size: 18px;
`
const EditIcon = styled(RiEditCircleLine)`
  font-size: 18px;
`
const DeleteIcon = styled(AiOutlineCloseCircle)`
  font-size: 18px;
`
const ErrorIcon = styled(AiOutlineExclamationCircle)`
  font-size: 18px;
`

export const LogDefinition = {
  category:{
    1: "category",
    2: "subcategory",
    3: "news",
    4: "tags",
    5: "user"
  },
  type:{
    1: "Add",
    2: "Edit",
    3: "Delete",
    4: "Error"
  },
  type_colors: {
    1: "green",
    2: "blue",
    3: "red",
    4: "red"
  },
  type_icon: {
    1: <AddIcon/>,
    2: <EditIcon/>,
    3: <DeleteIcon/>,
    4: <ErrorIcon/>
  }
}