import {makeAutoObservable, toJS} from "mobx";
import ApiGateway from "../api/ApiGateway";

class NewsStore {

  news = []
  tags = []

  totalItems = null;
  isLoadingData = false;

  constructor() {
    makeAutoObservable(this)
  }

  async loadNewsAndTags(id) {
    const tags = await ApiGateway.news.loadTags();
    this.tags = tags.result;
    if(id){
      let data = {id: id}
      const news = await ApiGateway.news.loadNews({
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(data)
      });
      this.news = news.result;
      return;
    }
    const news = await ApiGateway.news.loadNews();
    this.news = news.result ;

  }

  async createTags(data){
    let result = await ApiGateway.news.createTags({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });

    if(result.success === true){
      let val = JSON.stringify(data)
      this.tags.push({id: result.id, name:val})
    }

    return result;
  }

  async createNews(data){
    let result = await ApiGateway.news.createNews({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    return result;
  }

  async editNews(data){
    let result = await ApiGateway.news.editNews({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    return result;
  }

  async editSubCategory(data){
    let result = await ApiGateway.products.editSubCategory({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    return result;
  }

  async createSubCategory(data){
    let result = await ApiGateway.products.createSubCategory({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    return result;
  }

  async deleteSubCategory(data){
    let result = await ApiGateway.products.deleteSubCategory({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    if(result.success){
      this.subCategory = toJS(this.subCategory).filter(value => {
        return value.id != data.id;
      })
    }
    return result;
  }

  getProductsImage() {

  }

}

export default new NewsStore()