import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {Timeline} from "antd";
import {LogDefinition} from "../utils/LogDefinition";

const TimelineWrapper = styled.div`
`

const TimelineItem = styled(Timeline.Item)`
  font-size: 15px;
  font-weight: 700;
`
const TimeLineTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const TimeLineHeader = styled.span`
  
`
const TimeLineInfo = styled.span`
  font-size: 12px;
  font-weight: 500;
`

const PreviewDisplay = ({object}) => {

  return (
    <TimelineWrapper>
      <Timeline mode="right">
        {object.map((value) => {
          return (
            <TimelineItem dot={LogDefinition.type_icon[value.log_type]} color={LogDefinition.type_colors[value.log_type]} key={value.id} label={value.log_timestamp.split("T", 1)[0]}>
              <TimeLineTextWrapper>
                <TimeLineHeader>
                  {LogDefinition.type[value.log_type]}
                </TimeLineHeader>
                <TimeLineInfo>
                  Edited By: {value.username}
                </TimeLineInfo>
              </TimeLineTextWrapper>
            </TimelineItem>
          )
        })}
      </Timeline>
    </TimelineWrapper>
  )
}

export default observer(PreviewDisplay)