import { BiCategoryAlt, BiUser } from "react-icons/bi";

export const MenuItemsConfig = [
  {
    name: "user",
    key: "user",
    iconNode: <BiUser/>,
    requiredAccessLevel: 4,
    children:[
      {
        name: "list",
        key: 1.1,
      },
      {
        name: "create",
        key: 1.2,
      }
    ]
  },
  {
    name: "category",
    key: "category",
    iconNode: <BiCategoryAlt/>,
    requiredAccessLevel: 2,
    children:[
      {
        name: "list",
        key: 2.1,
      },
      {
        name: "create",
        key: 2.2,
      }
    ]
  },
  {
    name: "subcategory",
    key: "subcategory",
    iconNode: <BiCategoryAlt/>,
    requiredAccessLevel: 2,
    children:[
      {
        name: "list",
        key: 3.1,
      },
      {
        name: "create",
        key: 3.2,
      }
    ]
  },
  {
    name: "news",
    key: "news",
    iconNode: <BiCategoryAlt/>,
    requiredAccessLevel: 2,
    children:[
      {
        name: "list",
        key: 4.1,
      },
      {
        name: "create",
        key: 4.2,
      }
    ]
  },

]