import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {Form, Button} from "antd";
import translation from "../../shared/utils/translation";
import FormInputItem from "../../shared/components/forms/FormInputItem";
import Notifications from "../../shared/utils/Notifications";
import UserStore from "../../shared/stores/UserStore";
import {setUserLoggedIn} from "../../shared/stores/CookieStore";

const Wrapper = styled.div`
  margin: auto;
  padding: 10px;
`

const LabelText = styled.span`
  font-size: 15px;
`
const FormWrapper = styled.div`
  width: 20%;
`

const LoginPage = () => {

  const validateMessages = {
    required: translation("common.field.required"),
  };

  const onFinish = async(values) => {
    let status = await UserStore.loginUser({values});
    if(status.success === true){
      Notifications("success", translation("common.success"), translation("notification.data.login.account.success"))
      setUserLoggedIn(status.token)
      window.location.pathname = '/'
    }
    if(status.success === false){
      Notifications("error", translation("common.error"), translation("notification.data.login.account.error"))
    }
  };

  const onFinishFailed = () => {
    Notifications("error", translation("common.error"), translation("notification.fill.form"))
  };

  return (
    <Wrapper>
      <Form name="dynamic_rule" validateMessages={validateMessages} onFinishFailed={onFinishFailed} onFinish={onFinish} initialValues={{remember: true}}>
        <FormWrapper>
          <LabelText>{translation("common.username")}</LabelText>
          <FormInputItem
            name="username"
            required
          />
          <LabelText>{translation("common.password")}</LabelText>
          <FormInputItem
            name="password"
            required
            isPassword={true}
          />
          <Button type="primary" htmlType="submit">
            {translation("common.login")}
          </Button>
        </FormWrapper>
      </Form>
    </Wrapper>
  )
}

export default observer(LoginPage)