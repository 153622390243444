import UserStore from "../../../stores/UserStore";
import {MenuItemsConfig} from "../MenuItemsConfig";
import NewsStore from "../../../stores/NewsStore";
import CategoryStore from "../../../stores/CategoryStore";
import SubCategoryStore from "../../../stores/SubCategoryStore";

export default async function accessCheck(){
  let path = window.location.pathname.split("/",2)[1];
  let result;
  MenuItemsConfig.map(async(value)=> {
    if(path === value.name){
      result = value.requiredAccessLevel <= UserStore.accessLevel;
    }
    if(value.name === "category" && value.requiredAccessLevel <= UserStore.accessLevel)await CategoryStore.loadCategory();
    if(value.name === "subcategory" && value.requiredAccessLevel <= UserStore.accessLevel)await SubCategoryStore.loadSubCategory();
    if(value.name === "user" && value.requiredAccessLevel <= UserStore.accessLevel)await UserStore.loadUser();
    if(value.name === "news" && value.requiredAccessLevel <= UserStore.accessLevel)await NewsStore.loadNewsAndTags();
  })

  if(path === "login"){
    result = !UserStore.isLoggedIn;
  }

  return result

}

