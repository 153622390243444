import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import CategoryList from "./CategoryList";
import translation from "../../shared/utils/translation";
import TitlePrimary from "../../shared/components/text/TitlePrimary";
import CategoryEdit from "./CategoryEdit";
import CategoryCreate from "./CategoryCreate";

const Wrapper = styled.div`
`

const ContentWrapper = styled.div`
  width: 80%;
  margin: auto;
`

const TitleWrapper = styled.div`
  margin: 50px;
`

const CategoryWrapper = () => {
  let { event, id } = useParams();

  return (
    <Wrapper>
      <TitleWrapper><TitlePrimary small={true}>{translation(`common.${event}`)}</TitlePrimary></TitleWrapper>
      <ContentWrapper>
        {event === "list" && <CategoryList/>}
        {event === "edit" && <CategoryEdit categoryId={id}/>}
        {event === "create" && <CategoryCreate/>}
      </ContentWrapper>
    </Wrapper>
  )
}

export default observer(CategoryWrapper)