import * as React from "react"
import styled from "styled-components"
import {observer} from "mobx-react-lite";
import translation from "../../shared/utils/translation";
import TitlePrimary from "../../shared/components/text/TitlePrimary";
import LoginPage from "./LoginPage";
import {useEffect} from "react";

const Wrapper = styled.div`
`

const ContentWrapper = styled.div`
  width: 80%;
  margin: auto;
`

const TitleWrapper = styled.div`
  margin: 50px;
`

const LoginWrapper = () => {

  useEffect(()=> {

  }, [])

  return (
    <Wrapper>
      <TitleWrapper><TitlePrimary small={true}>{translation(`common.login`)}</TitlePrimary></TitleWrapper>
      <ContentWrapper>
        <LoginPage/>
      </ContentWrapper>
    </Wrapper>
  )
}

export default observer(LoginWrapper)